
const themesGak = {
  themes: {
    light: {
      primary: '#93D500',
      secondary: '#F1FADE',
      accent: '#1B535C',
      success: '#00D500',
      warning: '#FB8C00',
      error: '#E30613',
      buttons: '#93D500'
    }
  },
}

export default themesGak
